export default function DockView({dockList}) {
    const docks = dockList.map((d) => {
        let coveredDockStatus = d.covered ? "Couvert" : "";
        return <tr key={d.id}>
            <td>{d.capacity}</td>
            <td>{coveredDockStatus}</td>
            <td>
                <a href={"http://maps.apple.com/?q=" + d.latitude + "," + d.longitude}>{Math.round(d.distance)}</a>
            </td>
        </tr>
    })

    if (dockList.length > 0) {
        return (
            <>
                <table width="100%">
                    <thead>
                    <tr>
                        <th>Places</th>
                        <th>Type</th>
                        <th>Distance (mètres)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {docks}
                    </tbody>
                </table>
            </>
        )
    }
}
