import './App.css';
import DockView from "./components/dockview";
import {useEffect, useState} from 'react';
import logo from './logo.svg';

// const API_HOST = "http://localhost:8080"

const API_HOST = "https://www.velodock.fr"

function CloseToMyLocationButton({onUpdateDockList, cleanAddressData}) {
    const [searching, setSearching] = useState(false);

    function handleClick() {
        console.log('clicked on close to me');

        onUpdateDockList([])
        cleanAddressData()
        setSearching(true)

        function success(position) {
            console.log("Current position", position)
            fetch(API_HOST + "/api/docks?lng=" + position.coords.longitude + "&lat=" + position.coords.latitude)
                .then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            console.log("docks close to me", data.docks)
                            onUpdateDockList(data.docks)
                        })
                    } else {
                        console.log("Error while getting docks close to me", response)
                    }
                    setSearching(false)
                })
        }

        function error(error) {
            console.log("Error while getting current position", error)
        }

        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        }

        navigator.geolocation.getCurrentPosition(success, error, options)

    }

    return (
        <button onClick={handleClick} aria-busy={searching}>
            Emplacements proches de moi
        </button>
    );
}


function AddressListView({addressList, shown, onUpdateDockList}) {

    function searchDocksCloseToAddress(address) {
        console.log('searching for docks close to address', address);

        fetch(API_HOST + "/api/docks?lng=" + address.coordinates.longitude + "&lat=" + address.coordinates.latitude)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        console.log("docks close to an address", data.docks)
                        onUpdateDockList(data.docks)
                    })
                } else {
                    console.log("Error while getting docks close to me", response)
                    console.log("Error while getting docks close to me", response)
                }
            })
    }


    const addresses = addressList.map((address) => {
        return <tr key={address.fullAddress}
                   onClick={() => searchDocksCloseToAddress(address)}>
            <td>{address.fullAddress}</td>
        </tr>
    })


    if (shown) {
        if (addressList.length > 0) {
            return (
                <>
                    <table>
                        <tbody>
                        {addresses}
                        </tbody>
                    </table>
                </>
            )
        }
    }
}

function DocksByAddressBlock({onUpdateDockList, addressList, setAddressList, address, setAddress}) {
    const [shown, setShown] = useState(true);

    function updateDockList(docks) {
        onUpdateDockList(docks);
        setShown(false);
    }

    return (
        <>
            <AddressForm
                address={address}
                setAddress={setAddress}
                onUpdateAddressList={(addr) => {
                    setAddressList(addr)
                    setShown(true)
                }}
                onResetDockList={() => updateDockList([])}/>

            <AddressListView addressList={addressList} shown={shown} onUpdateDockList={updateDockList}/>
        </>
    )
}

function AddressForm({onUpdateAddressList, onResetDockList, address, setAddress}) {
    const [isSearching, setIsSearching] = useState(false);

    function handleSubmit(event) {
        event.preventDefault()
        setIsSearching(true)
        console.log("Filled address:", address)
        fetch(API_HOST + "/api/addresses?address=" + encodeURI(address))
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (data) {
                        console.log("Got response:", data)

                        if (data.results.length > 0) {
                            if (data.results[0].relevance > 0.9) {
                                let threshold = data.results[0].relevance
                                data.results = data.results.filter(r => r.relevance >= threshold)
                            }
                        }
                        onUpdateAddressList(data.results)
                    })
                } else {
                    console.log("Error while getting addresses", response)
                }
                setIsSearching(false)
            })
    }

    function handleChangedAddress(e) {
        setAddress(e.target.value);
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type="search"
                   id="address"
                   placeholder="ou proche d'une adresse"
                   value={address}
                   onChange={handleChangedAddress}
                   onFocusCapture={onResetDockList}
            />
            <button type="submit" aria-busy={isSearching}>Chercher</button>
        </form>
    );
}


function App() {
    const [address, setAddress] = useState('');
    const [addressList, setAddressList] = useState([]);
    const [dockList, setDockList] = useState([]);

    return (
        <>
            <div className="App">
                <p></p>
                <section>
                    <img src={logo} height="25%" alt="logo"/>
                </section>
                <hgroup>
                    <h1>VeloDock</h1>
                    <h2>VeloDock vous permet de trouver rapidement et facilement des emplacements pour garer votre
                        vélo dans toute la France.</h2>
                </hgroup>
                <section>
                    <CloseToMyLocationButton onUpdateDockList={setDockList}
                                             cleanAddressData={() => {
                                                 setAddressList([])
                                                 setAddress('')
                                             }}/>
                </section>

                <DocksByAddressBlock onUpdateDockList={setDockList}
                                     addressList={addressList} setAddressList={setAddressList}
                                     address={address} setAddress={setAddress}/>

                <DockView dockList={dockList}/>
            </div>

            <footer style={{textAlign: "center"}}>
                <small>Made in Angers, Christian Sperandio 2023</small>
            </footer>
        </>
    );
}

export default App;
